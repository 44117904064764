import React from "react";
import styles from './Register.module.css';

export const Register = () => {
    return (
        <React.Fragment>
            <div className="min-vh-100 col-12 d-inline-flex flex-column">
                
            </div>
        </React.Fragment>
    )
}